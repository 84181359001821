import { Tooltip } from "bootstrap";

const addPacketIcon = element => {
  const i = document.createElement("i");

  i.classList.add(
    "fas",
    "fa-box",
    "fa-stack-1x",
    "small-font",
    "z-index-5",
    "icon-packet",
    "position-absolute",
    "robot-overlay-position"
  );

  element.appendChild(i);
};

const toggleMapLegendModal = (modalElement, open) => {
  if (!modalElement) return;

  const HIDEN_CLASS = "hidden";
  const { classList } = modalElement;

  if (open === true) {
    classList.remove(HIDEN_CLASS);
  } else if (open === false) {
    classList.add(HIDEN_CLASS);
  } else if (open == null) {
    classList.toggle(HIDEN_CLASS);
  }
};

const toggleTooltipTitle = (tooltipElement, currentTitle, quickUpdate = false) => {
  if (!tooltipElement || !currentTitle) return;

  const showTooltipTitle = tooltipElement.dataset.showTitle;
  const hideTooltipTitle = tooltipElement.dataset.hideTitle;

  const buttonTooltip = Tooltip.getInstance(tooltipElement);

  tooltipElement.setAttribute(
    "data-bs-original-title",
    currentTitle === showTooltipTitle ? hideTooltipTitle : showTooltipTitle
  );

  if (quickUpdate) buttonTooltip.show();
};

const initializePacketIcons = mapLegendElement => {
  const packetIconElements = mapLegendElement.querySelectorAll(".packet-icon");
  packetIconElements.forEach(element => addPacketIcon(element));
};

const initializeMapLegendButtons = mapLegendElement => {
  const mapLegendButton = mapLegendElement.querySelector(".map-legend-button");
  const mapLegendModal = mapLegendElement.querySelector(".map-legend-modal");
  const mapLegendModalCloseButton = mapLegendModal.querySelector(".btn-close");

  [mapLegendButton, mapLegendModalCloseButton].forEach((button, index) => {
    button.addEventListener("click", () => {
      const currentTooltipTitle = mapLegendButton.dataset.bsOriginalTitle;

      toggleMapLegendModal(mapLegendModal, index === 1 ? false : null);
      toggleTooltipTitle(mapLegendButton, currentTooltipTitle, index === 0 ? true : false);
      mapLegendButton.classList.toggle("active");
    });
  });
  
  // Hide modal when clicking anywhere on body EXCEPT the modal and the button
  document.body.addEventListener("click", e => {
    const clickInsideModal = mapLegendModal.contains(e.target);
    const clickModalButton = mapLegendButton.contains(e.target);
    
    if (!clickInsideModal && !clickModalButton) {
      const currentTooltipTitle = mapLegendButton.dataset.bsOriginalTitle;
      
      toggleMapLegendModal(mapLegendModal, false);
      toggleTooltipTitle(mapLegendButton, currentTooltipTitle, false);
      mapLegendButton.classList.remove("active");
    }
  });
};

const initializeMapLegend = () => {
  const mapLegend = document.querySelector(".map-legend");
  if (!mapLegend) return;

  initializePacketIcons(mapLegend);
  initializeMapLegendButtons(mapLegend);
};

document.addEventListener("turbo:load", initializeMapLegend);
