document.addEventListener("turbo:load", () => {
  const modal = document.querySelector("#chute_routing_modal");
  if (!modal) return;

  modal.addEventListener("show.bs.modal", event => {
    const button = event.relatedTarget;
    modal.querySelector(".modal-title ").textContent =
      button.getAttribute("data-title");
    modal.querySelector("#chute_routing_type").textContent =
      button.getAttribute("data-type");
    modal.querySelector("#config").textContent =
      button.getAttribute("data-config");
  });
});
