document.addEventListener("turbo:load", () => {
  initializeMapQRModal();
});

const initializeMapQRModal = () => {
  const modal = document.querySelector("#map-qr-modal");
  if (!modal) return;

  const header = modal.querySelector("h5");
  const qrPlace = modal.querySelector(".qr-code-flex");

  modal.addEventListener("show.bs.modal", async event => {
    const button = event.relatedTarget;
    const itemCode = button.getAttribute("data-bs-item-code");
    const qrPath = button.getAttribute("data-bs-qr-path");

    qrPlace.innerHTML = "";
    header.textContent = itemCode;

    try {
      const response = await fetch(qrPath, { credentials: "include" });
      const { data } = await response.json();
      const parser = new DOMParser();
      const htmlData = parser.parseFromString(data, "text/html");
      const tableQR = htmlData.querySelector("table");
      qrPlace.append(tableQR);
    } catch (error) {
      qrPlace.append(error.message);
    }
  });
};
