["turbo:load", "cocoon:after-insert"].forEach(event =>
  document.addEventListener(event, () => initDateTimePicker(), false)
);

const initDateTimePicker = () => {
  document.querySelectorAll(".date-time-picker").forEach(element => {
    const options = JSON.parse(element.dataset.dateOptions);
    const levelConfigInterval = document.querySelector(
      "#level_config_interval"
    );

    if (levelConfigInterval) {
      options.format = "d.m.Y";
      options.time_24hr = false;
      options.enableTime = false;
    }

    flatpickr(element, {
      dateFormat: options.format,
      time_24hr: options.time_24hr,
      enableTime: options.enableTime,
      noCalendar: options.no_calendar,
      defaultDate: "today",
      locale: {
        ...options.locale,
        firstDayOfWeek: 1,
      },
      disable: [
        date => {
          return options.hide_weekends
            ? date.getDay() === 6 || date.getDay() === 0
            : false;
        },
      ],
    });
  });
};
