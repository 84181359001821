document.addEventListener("turbo:load", () => {
  const elements = document.querySelectorAll(".fs-dynamic-h-2");
  if (!elements.length) return;

  const header = document.querySelector(".map-print-sorter-header");
  createDynamicFontSizes(elements, header);
});

const createDynamicFontSizes = (elements, wrapper) => {
  elements.forEach(element => {
    setDynamicFontSize(element, "width", 2);
    if (!wrapper) return;

    setSameFontSizeForSiblings(element, wrapper);
  });
};

// TODO [Frontend-Kit]
const setDynamicFontSize = (element, orientation = "width", multiplier = 1) => {
  const parentSize = getComputedStyleSize(element.parentElement, orientation);
  const textLength = element.innerText.length;

  let newFontSize = Math.floor((parentSize / textLength) * multiplier);

  if (newFontSize >= parentSize) {
    newFontSize =
      multiplier > 1 ? parentSize / (multiplier * 0.75) : parentSize * 0.75;
  }

  element.style.fontSize = `${newFontSize}px`;
};

// TODO [Frontend-Kit]
const getComputedStyleSize = (element, styleProperty) => {
  return +window
    .getComputedStyle(element)
    [styleProperty].replace(/[a-z%]/g, "");
};

const setSameFontSizeForSiblings = (element, wrapper) => {
  const newFontSize = element.style.fontSize;
  const titles = wrapper.querySelectorAll("h2, h3");

  titles.forEach(title => {
    title.style.fontSize = newFontSize;
  });
};
