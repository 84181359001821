import Masonry from "masonry-layout";

let msnry;

document.addEventListener("turbo:load", () => {
  const masonryRowElement = document.querySelector(".row.masonry");

  if (!masonryRowElement) return;

  msnry = new Masonry(masonryRowElement, {
    percentPosition: true,
    horizontalOrder: true,
    transitionDuration: 0,
    stagger: 0,
  });
});

document.addEventListener("turbo:before-cache", () => {
  if (msnry) { 
    msnry.destroy(); 
  };
});
