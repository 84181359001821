const CHARGER_ID = "data-charger-id";
const CHARGE = "charge";
const CHARGE_ICON = "charge-icon";

document.addEventListener("turbo:load", () => {
  window.chargers = new Chargers();
});

export class Chargers {
  #lastId = 0;

  constructor() {
    this.items = [];

    this.#setup();
  }

  // Static Methods

  static initialized = () => !!window.chargers;

  static isCharger = element => {
    return element.classList.contains(CHARGE);
  };

  // Public Methods

  updateChargers = (cell, robotId) => {
    const foundItem = this.items.find(item => item.robotId === robotId);
    const cellCharger = Chargers.isCharger(cell);

    if (!foundItem && cellCharger) {
      const id = cell.getAttribute(CHARGER_ID);

      this.#toggleCharger(id, robotId);
    } else if (foundItem && !cellCharger) {
      this.#toggleCharger(foundItem.id);
    }
  };

  // Private Methods

  #setup = () => {
    const chargers = document.querySelectorAll(`.${CHARGE}`);
    if (!chargers) return;

    this.items = [...chargers].map(charger => this.#createChargerItem(charger));
  };

  #createChargerItem = element => {
    const nextElement = element.nextElementSibling;
    if (!nextElement) return;

    let icon;

    if (nextElement.classList.contains(CHARGE_ICON)) {
      const iconElement = this.#createIconElement();
      nextElement.append(iconElement);

      icon = {
        wrapper: nextElement,
        element: iconElement
      };
    }

    const newCharger = {
      id: this.#lastId,
      element,
      active: false,
      robotId: null
    };

    element.setAttribute(CHARGER_ID, newCharger.id);

    this.#lastId++;

    return icon ? { ...newCharger, icon } : newCharger;
  };

  #createIconElement = () => {
    const icon = document.createElement("i");
    icon.className = "fas fa-bolt icon-without-text";

    return icon;
  };

  #toggleCharger = (id, robotId = null) => {
    this.items = this.items.map(item => {
      if (item.id != id) return item;

      return {
        ...item,
        active: !!robotId,
        robotId
      };
    });

    this.#updateChargerIcons();
  };

  #updateChargerIcons = () => {
    this.items.forEach(item => {
      item.icon.element.classList.toggle("success", item.active);
    });
  };
};

export default Chargers;
