import { Modal } from "bootstrap";

document.addEventListener("turbo:load", () => {
  const sorters = document.querySelectorAll("td.chute span");
  const token = document.getElementsByName("csrf-token")[0];
  const tokenContent = token && token.content;
  const modal = document.getElementById("config-modal");
  let configId, targetConfigId, configPath, configModal;

  if (modal) {
    configModal = new Modal(modal);
  }

  function toggleModal(notice) {
    configModal.show();
    modal.querySelector(".modal-title").innerText = notice;
    setTimeout(() => configModal.hide(), 2000);
  }

  function setConfigId() {
    configId = this.getAttribute("data-bs-chute-id");
  }

  function setConfigProperty(event) {
    event.preventDefault();

    const copyPath = this.parentElement.getAttribute("data-copy_path");
    const chuteId = this.parentElement.getAttribute("data-chute_id");

    if (copyPath && chuteId) {
      configPath = this.parentElement.getAttribute("data-copy_path");
      targetConfigId = this.parentElement.getAttribute("data-chute_id");
    }
  }

  async function setConfigChute() {
    let notice;

    if (configId !== targetConfigId && configPath) {
      const settings = {
        method: "POST",
        headers: {
          "X-CSRF-Token": tokenContent,
        },
      };

      try {
        const response = await fetch(
          `${location.origin}${configPath}&chute_id=${configId}`,
          settings
        );
        const data = await response.json();
        notice = data.notice;
      } catch (error) {
        notice = error.notice;
      } finally {
        toggleModal(notice);
      }
    }
  }

  sorters.forEach(sorter => {
    sorter.addEventListener("dragstart", setConfigId);
    sorter.addEventListener("drop", setConfigChute);
    sorter.addEventListener("dragover", setConfigProperty);
  });
});
