import Styleguide from "web-packeta-styleguide/js/styleguide";

const initTooltipList = () => {
  const tooltipList = [...document.querySelectorAll('[data-toggle="tooltip"]')];
};

const visitCallback = url => {
  Turbo.visit(url);
};

document.addEventListener("turbo:load", () => {
  Styleguide.initContextSwitch();
  Styleguide.initDateFilter();
  Styleguide.removeRipple("btn");
  Styleguide.initFilterSelectMany(visitCallback);
  Styleguide.initFilterSelectOne(visitCallback);
  Styleguide.initSearch(visitCallback);
  Styleguide.addStickyOffset(".table-sticky thead", ".navbar");
  initTooltipList();
});
