const SERVICE_AREA = "service-area";
const CODE = "code";
const CODE_COLLISION = "code-collision";

const fixIconCodeCollision = (serviceArea, index) => {
  const parentRow = serviceArea.parentElement;
  const parentNextSibling = parentRow.nextElementSibling;
  const nextSiblingServiceArea = parentNextSibling.querySelector(`.${SERVICE_AREA}`);

  const extendCodeClass = element => {
    const code = element.querySelector(`.${CODE}`);
    if (!code || code.classList.contains(CODE_COLLISION)) return;

    code.classList.add(CODE_COLLISION);
  };

  if (nextSiblingServiceArea) {
    if (index === 0) {
      extendCodeClass(serviceArea);
    }

    extendCodeClass(nextSiblingServiceArea);
  }
};

const markServiceArea = () => {
  const firstServiceArea = document.querySelector(`.${SERVICE_AREA}`);
  if (!firstServiceArea) return;

  const nextElement = firstServiceArea.nextElementSibling;

  if (nextElement && !nextElement.classList.contains(SERVICE_AREA)) {
    const allServiceAreas = document.querySelectorAll(`.${SERVICE_AREA}`);

    allServiceAreas.forEach((area, index) => {
      area.classList.add("map-spine");
      fixIconCodeCollision(area, index);
    });
  }
};

document.addEventListener("turbo:load", () => {
  markServiceArea();
});
