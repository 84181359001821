document.addEventListener("turbo:load", () => {
  switchByTestType();
  switchBySortingType();
});

function switchBySortingType() {
  const sortingTypeSelect = document.getElementById("system_test_sorting_type");
  if (!sortingTypeSelect) {
    return;
  }
  sortingTypeSelect.addEventListener("change", event => {
    const sortingType = event.detail.value;
    hideBySortingType(sortingType);
  })
}

function hideBySortingType(sortingType) {
  document.querySelectorAll(".system_test_loading_terminals").forEach((el) => {
    if (sortingType === "rs") {
      el.classList.remove("d-none");
    } else {
      el.classList.add("d-none");
    }
  });
}

function switchByTestType() {
  const typeSelect = document.getElementById("system_test_test_type");
  if (!typeSelect) {
    return;
  }
  hideByType(typeSelect.value);

  typeSelect.addEventListener("change", event => {
    const type = event.detail.value;
    hideByType(type);
  });
}

function hideByType(type){
  document.querySelectorAll(`.showed-by-type [data-for-test-type][data-for-test-type="${type}"]`).forEach((el) => {
    el.classList.remove("d-none");
  })
  document.querySelectorAll(`.showed-by-type [data-for-test-type]:not([data-for-test-type="${type}"])`).forEach((el) => {
    el.classList.add("d-none");
  })
}
