import "./index.scss";

import { application } from "../../controllers/application";
import { Controller } from "@hotwired/stimulus";

class ChutePopover extends Controller {
  static targets = ["activate", "deactivate", "parcel"];
  static values = { code: String, inactiveTitle: String, activeTitle: String, readonly: Boolean };

  connect() {
    if (!this.hasParcel()) {
      return this.showNoParcel();
    }
    if (this.isActive()) {
      this.deactivate();
    } else {
      this.activate();
    }
  }

  setActiveTitle() {
    this.headerElement.innerHTML = this.activeTitleValue;
  }

  setInactiveTitle() {
    this.headerElement.innerHTML = this.inactiveTitleValue;
  }

  showNoParcel() {
    this.parcelTarget.classList.remove("d-none");
    this.activateTarget.classList.add("d-none");
    this.deactivateTarget.classList.add("d-none");
  }

  deactivate() {
    this.setActiveTitle();
    this.parcelTarget.classList.add("d-none");
    this.activateTarget.classList.add("d-none");
    if (this.readonlyValue) {
      this.deactivateTarget.classList.add("d-none");
    } else {
      this.deactivateTarget.classList.remove("d-none");
    }
  }

  activate() {
    this.setInactiveTitle();
    this.parcelTarget.classList.add("d-none");
    if (this.readonlyValue) {
      this.activateTarget.classList.add("d-none");
    } else {
      this.activateTarget.classList.remove("d-none");
    }
    this.deactivateTarget.classList.add("d-none");
  }

  get td() {
    return document.querySelector(`[data-code="${this.codeValue}"]`);
  }

  get baseElement() {
    return this.element.closest(".popover");
  }

  get headerElement() {
    return this.baseElement.querySelector(".popover-header");
  }

  isActive() {
    const classList = this.td.classList;

    return classList.contains("success");
  }

  hasParcel() {
    return this.td.querySelector(".icon-parcel").innerHTML !== "";
  }
}

application.register("chute-popover", ChutePopover);
