document.addEventListener("turbo:load", () => {
  const memberActions = document.querySelectorAll(".member_actions");

  memberActions.length && showDropdownUntilClose(memberActions);
});

const showDropdownUntilClose = wrappers => {
  wrappers.forEach(table => {
    const dropdowns = table.querySelectorAll(".dropdown-menu");
    if (!dropdowns.length) return;

    dropdowns.forEach(dropdown => {
      const dropdownBtn = dropdown.parentNode;

      dropdownBtn.addEventListener("click", () => {
        table.classList.add("visible");

        document.addEventListener("click", event => {
          if (!dropdownBtn.contains(event.target)) {
            table.classList.remove("visible");
          }
        });
      });
    });
  });
};
