import { addChoices, choicesOptions, getChoices } from "./choices";
import Choices from "choices.js";

const FORMS_OPTIONS = {
  AUTOMATIC: "automatic",
  CUSTOM: "custom",
  DEPOTS: "depots",
  REGIONS_V1: "regions",
  REGIONS_V2: "regions_v2",
  PICKUP_POINTS: "pickup_points",
  PICKUP_POINTS_V2: "pickup_points_v2",
  EVENING: "evening",
  NIGHT: "night",
  MORNING: "morning",
};

const CLASSES = {
  DISPLAY_NONE: "d-none",
};

document.addEventListener("turbo:load", () => {
  const typeSelect = document.querySelector("#level_config_config_type");
  if (!typeSelect) return;

  const elementsStore = {
    algorithmSelect: document.querySelector("#level_config_algorithm"),
    daytimeSelect: document.querySelector("#level_config_daytime_type"),
    intervalSelect: document.querySelector("#level_config_interval"),
    intervalWrapper: document.querySelector("#custom-interval-wrapper"),
    typeSelect: typeSelect,
    typeWrapper: document.querySelector("#automatic-wrapper"),
    routePreferencesWrapper: document.querySelector("#route-preferences"),
    regionSelectsWrapper: document.querySelector("#region-selects-wrapper"),
    customWrapperTypes: document.querySelector("#custom_wrapper_types"),
    pickupPointV2Wrapper: document.querySelector(
      "#pickup-point-v2-select-wrapper"
    ),
    templateSelectWrapper: document.querySelector("#template-select"),
    configTitle: document.querySelector("#level_config_title"),
    newConfig: document.querySelector("form.new_level_config"),
    editConfig: document.querySelector("form.edit_level_config"),
    pickupPointV2SelectWrapper: document.querySelector(
      "#pickup-point-v2-select-wrapper"
    ),
  };
  const algorithmForms = [
    {
      algorithm: FORMS_OPTIONS.DEPOTS,
      type: FORMS_OPTIONS.DEPOTS,
      wrapper: document.querySelector("#depots-select-wrapper"),
    },
    {
      algorithm: FORMS_OPTIONS.PICKUP_POINTS,
      type: FORMS_OPTIONS.PICKUP_POINTS,
      wrapper: document.querySelector("#pickup-point-select-wrapper"),
    },
    {
      algorithm: FORMS_OPTIONS.PICKUP_POINTS_V2,
      type: FORMS_OPTIONS.PICKUP_POINTS_V2,
      wrapper: document.querySelector("#pickup-point-select-wrapper"),
    },
    {
      algorithm: FORMS_OPTIONS.REGIONS_V1,
      type: FORMS_OPTIONS.REGIONS_V1,
      wrapper: elementsStore.regionSelectsWrapper,
    },
    {
      algorithm: FORMS_OPTIONS.REGIONS_V2,
      type: FORMS_OPTIONS.REGIONS_V2,
      wrapper: elementsStore.regionSelectsWrapper,
    },
  ];

  generateConfigTitle(elementsStore);
  initDaytimeSelect(elementsStore);
  initIntervalSelect(elementsStore);
  initRegionMapSelects(elementsStore);
  initTypeSelect(elementsStore, algorithmForms);
  initTemplateSelect(elementsStore);

  elementsStore.typeWrapper.addEventListener("cocoon:after-insert", () => {
    initChuteRoutingTypeSelect(elementsStore.customWrapperTypes, true);
  });
});

const initAlgorithmSelect = (store, forms, shift) => {
  changeForm(shift.type, forms);
  if (store.editConfig) {
    initChuteRoutingTypeSelect(store.customWrapperTypes);
  }

  store.algorithmSelect.addEventListener("change", event => {
    const type = event.detail.value;
    const showForm = findWrapperForDisplay(type, forms);

    changeForm(type, forms);

    type !== FORMS_OPTIONS.REGIONS_V1 && addNewChoices(findSelect(showForm));

    toggleDisplayNone(
      store.customWrapperTypes,
      type !== FORMS_OPTIONS.REGIONS_V2 &&
        type !== FORMS_OPTIONS.PICKUP_POINTS_V2
    );

    toggleDisplayNone(
      store.pickupPointV2Wrapper,
      type !== FORMS_OPTIONS.PICKUP_POINTS_V2
    );

    if (
      type === FORMS_OPTIONS.REGIONS_V2 ||
      type === FORMS_OPTIONS.PICKUP_POINTS_V2
    ) {
      initChuteRoutingTypeSelect(store.customWrapperTypes);
    } else {
      const selects = store.customWrapperTypes.querySelectorAll("select");

      if (!selects.length) return;

      selects.forEach(select => {
        destroyChoices(select);
      });
    }
  });
};

const initDaytimeSelect = store => {
  store.daytimeSelect.addEventListener("change", event => {
    const type = event.detail.value;

    generateConfigTitle(store);

    if (store.typeSelect.value === FORMS_OPTIONS.AUTOMATIC) {
      const cont = store.templateSelectWrapper;
      if (!cont) return;

      const routePreferencesSelects =
        store.routePreferencesWrapper.querySelectorAll("select");
      const templatesUrl = cont.dataset.templatesUrl;
      const templateSelect = document.querySelector(
        "#level_config_template_id"
      );
      const templateChoice =
        window.choicesMap[templateSelect.dataset.choicesID];
      const fetchUrl = getTemplateFetchUrl(templatesUrl, type);
      const algorithmChoice = getChoices(store.algorithmSelect);

      setChoiceSearchResults(templateChoice, fetchUrl);
      toggleDisplayNone(
        store.routePreferencesWrapper,
        type == FORMS_OPTIONS.EVENING
      );

      switch (type) {
        case FORMS_OPTIONS.EVENING:
          routePreferencesSelects.forEach(select => {
            destroyChoices(select);
          });

          algorithmChoice.setChoiceByValue(FORMS_OPTIONS.REGIONS_V2);
          break;
        case FORMS_OPTIONS.MORNING:
        case FORMS_OPTIONS.NIGHT:
          routePreferencesSelects.forEach(select => {
            const selectChoices = getChoices(select);
            if (selectChoices) return;

            addNewChoices(select);
          });

          algorithmChoice.setChoiceByValue(FORMS_OPTIONS.PICKUP_POINTS_V2);
          break;
        default:
          break;
      }
    }
  });
};

const initIntervalSelect = store => {
  store.intervalSelect.addEventListener("change", event => {
    const type = event.detail.value;

    toggleDisplayNone(store.intervalWrapper, type !== FORMS_OPTIONS.CUSTOM);
  });
};

const initTypeSelect = (store, algorithmForms) => {
  store.typeSelect.value === FORMS_OPTIONS.AUTOMATIC &&
    showAutomaticForms(store, algorithmForms);

  store.typeSelect.addEventListener("change", event => {
    const type = event.detail.value;
    const btnCancel = document.querySelector(".form-actions a.text-danger");
    const returnLink = btnCancel.href;
    const newLink =
      returnLink.substr(0, returnLink.lastIndexOf("=") + 1) + type;

    btnCancel.setAttribute("href", `${newLink}`);

    generateConfigTitle(store);
    toggleDisplayNone(store.typeWrapper, type !== FORMS_OPTIONS.AUTOMATIC);

    store.typeSelect.value === FORMS_OPTIONS.AUTOMATIC &&
      showAutomaticForms(store, algorithmForms);
  });
};

const initTemplateSelect = store => {
  const { templateSelectWrapper } = store;
  const templateSelect = templateSelectWrapper.querySelector("select");
  const disabledElements = templateSelectWrapper.querySelectorAll(".disabled");

  const toggleCheckbox = state => {
    disabledElements.forEach(element => {
      element.classList.toggle("disabled", !state);
      element.toggleAttribute("disabled", !state);
      !state && (element.checked = false);
    });
  
  };

  templateSelect.addEventListener("change", () => {
    toggleCheckbox(!!templateSelect.value);
  });
};

const initRegionMapSelects = store => {
  const addButton = document.querySelector("#add-region-select");
  const container =
    store.regionSelectsWrapper.querySelector(".depots-container");
  const template = store.regionSelectsWrapper.querySelector(".template");
  const wrapper = template.querySelector(".wrapper");
  const regions = JSON.parse(template.dataset.regions);

  addButton.addEventListener("click", () => {
    const clone = cloneRegionRow(wrapper, regions);

    container.appendChild(clone);
  });

  container.querySelectorAll(".wrapper").forEach(el => {
    initRegionRow(el, wrapper, regions, true);
  });
};

const initRegionRow = (row, regions, enableAll) => {
  const depotsSelect = row.querySelector("select.depots");
  const regionsSelect = row.querySelector("select.regions");
  const removeRow = row.querySelector(".remove-row");

  depotsSelect.disabled = false;
  enableAll && (regionsSelect.disabled = false);

  const regionChoices = addNewChoices(regionsSelect);

  addNewChoices(depotsSelect);

  removeRow.addEventListener("click", event => {
    event.target.closest(".wrapper").remove();
  });

  depotsSelect.addEventListener("change", event => {
    const selectedDepot = event.detail.value;

    regionChoices.clearStore();

    if (selectedDepot.length) {
      regionsSelect.disabled = false;
      regionChoices.enable();
      regionChoices.setChoices(
        regions[selectedDepot],
        "barcode",
        "title",
        true
      );
    } else {
      regionChoices.clearChoices();
      regionChoices.disable();
    }
  });
};

const initChuteRoutingTypeSelect = (wrapper, initNew = false) => {
  const nestedFields = wrapper.querySelectorAll(".nested-fields");
  const lastNestedField = Array.from(nestedFields).at(-1);
  const initNewChoices = initNew;

  if (initNewChoices) {
    initNestedFields(lastNestedField, initNewChoices);
  } else {
    nestedFields.forEach(forms => {
      initNestedFields(forms, initNewChoices);
    });
  }
};

const initChuteRoutingSelect = (
  chuteRoutingForm,
  wrapperForm,
  initNewChoices
) => {
  const select = findSelect(chuteRoutingForm);
  let selectValue = select.value;
  const toggleWrapperForm = selectValue =>
    selectValue
      ? wrapperForm.classList.remove(CLASSES.DISPLAY_NONE)
      : wrapperForm.classList.add(CLASSES.DISPLAY_NONE);

  toggleWrapperForm(selectValue);

  if (initNewChoices) {
    const wrapperFormSelect = findSelect(wrapperForm);

    addNewChoices(select);
    addNewChoices(wrapperFormSelect);
  }

  chuteRoutingForm.addEventListener("change", event => {
    selectValue = event.detail.value;

    toggleWrapperForm(selectValue);
  });
};

const initNestedFields = (forms, initNewChoices) => {
  const chuteType = forms.querySelector(".chute-type");
  const chuteTypeSelect = findSelect(chuteType);
  let chuteTypeSelectValue = chuteTypeSelect.value;
  const wrapperForm = forms.querySelector(".wrapper_type");
  const destroyBtn = forms.querySelector(".remove_fields");
  const chuteTypeValues = [
    {
      type: "expedition",
      wrapper: forms.querySelector(".pickup-points-wrapper"),
    },
    {
      type: "microdepot_expedition",
      wrapper: forms.querySelector(".microdepots-wrapper"),
    },
    {
      type: "line",
      wrapper: forms.querySelector(".routings-wrapper"),
    },
    {
      type: "return_client",
      wrapper: forms.querySelector(".return-routings-wrapper"),
    },
    {
      type: "pickup_point_route",
      wrapper: forms.querySelector(".pickup-point-routes-wrapper"),
    },
    {
      type: "external_carrier_route",
      wrapper: forms.querySelector(".external-carrier-routes-wrapper"),
    },
  ];

  initChuteRoutingSelect(
    findWrapperForDisplay(chuteTypeSelectValue, chuteTypeValues),
    wrapperForm,
    initNewChoices
  );
  changeForm(chuteTypeSelectValue, chuteTypeValues);

  initNewChoices && addNewChoices(chuteTypeSelect);

  chuteType.addEventListener("change", event => {
    chuteTypeSelectValue = event.detail.value;
    const showForm = findWrapperForDisplay(
      chuteTypeSelectValue,
      chuteTypeValues
    );

    changeForm(chuteTypeSelectValue, chuteTypeValues);
    destroyChoices(findSelect(wrapperForm));
    initChuteRoutingSelect(showForm, wrapperForm, true);

    wrapperForm.classList.add(CLASSES.DISPLAY_NONE);
  });

  destroyBtn.addEventListener("click", () => {
    const allForms = forms.querySelectorAll("select");

    allForms.forEach(form => {
      destroyChoices(form);
    });

    forms.classList.add(CLASSES.DISPLAY_NONE);
  });
};

const addNewChoices = select => {
  return addChoices(new Choices(select, choicesOptions(select)));
};

const cloneRegionRow = (wrapper, regions) => {
  const clone = wrapper.cloneNode(true);

  initRegionRow(clone, wrapper, regions);

  return clone;
};

const destroyChoices = element => {
  const choices = getChoices(element);

  if (!choices) return;

  choices.enable();
  choices.destroy();
  window.choices.splice((window.choices.indexOf(choices), 1));
  delete window.choicesMap[element.dataset.choicesID];
  delete element.dataset.choicesID;
};

const findWrapperForDisplay = (value, objectForm) => {
  const valueIndex = findIndexInArray(objectForm, "type", value);
  const showForm = objectForm[valueIndex].wrapper;

  return showForm;
};

const findIndexInArray = (arraytosearch, key, valuetosearch) => {
  for (let i = 0; i <= arraytosearch.length; i++) {
    if (arraytosearch[i][key] == valuetosearch) {
      return i;
    }
  }
};

const findSelect = el => {
  return el.querySelector("select");
};

const generateConfigTitle = store => {
  const choices = window.choicesMap[store.daytimeSelect.dataset.choicesID];
  const daytimeText = choices.getValue()?.label;
  if (!daytimeText) return;

  const currentDate = new Date();
  const timeText = currentDate.toLocaleTimeString("cs-CZ");
  const dd = currentDate.getDate();
  const mm = currentDate.getMonth() + 1;
  const yyyy = currentDate.getFullYear();

  store.configTitle.value = `${daytimeText} ${dd}.${mm}.${yyyy} ${timeText}`;
};

const getTemplateFetchUrl = (startUrl, value) => {
  const selectedDaytimeType = value;
  const urlConnector = startUrl.includes("?") ? "&" : "?";

  return `${startUrl}${urlConnector}&daytime_type=${selectedDaytimeType}`;
};

const changeForm = (value, objectForm) => {
  const showForm = findWrapperForDisplay(value, objectForm);

  objectForm.forEach(value => {
    const wrapper = value.wrapper;
    toggleDisplayNone(wrapper, wrapper !== showForm);
    wrapper.classList.contains(CLASSES.DISPLAY_NONE) &&
      destroyChoices(findSelect(wrapper));
  });
};

const setChoiceSearchResults = (choice, fetchUrl) => {
  choice.setChoices(async () => {
    const response = await fetch(fetchUrl, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    choice.clearChoices();

    return data.data;
  });
};

const showAutomaticForms = (store, algorithmForms) => {
  store.typeWrapper.classList.remove(CLASSES.DISPLAY_NONE);

  const choices = window.choicesMap[store.algorithmSelect.dataset.choicesID];
  let algorithmForm;

  if (store.newConfig) {
    algorithmForm =
      store.daytimeSelect.value === FORMS_OPTIONS.EVENING
        ? algorithmForms[4]
        : algorithmForms[2];
  } else {
    const value = store.algorithmSelect.value;

    algorithmForms.forEach(form => {
      if (form.type === value) {
        algorithmForm = form;
      }
    });
  }

  initAlgorithmSelect(store, algorithmForms, algorithmForm);

  toggleDisplayNone(
    store.routePreferencesWrapper,
    store.daytimeSelect.value == FORMS_OPTIONS.EVENING
  );

  choices.setChoiceByValue(algorithmForm.algorithm);
};

const toggleDisplayNone = (element, condition) => {
  element.classList.toggle(CLASSES.DISPLAY_NONE, condition);
};
