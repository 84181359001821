let intervalId;
let currentTimeElements;

const getCurrentTimer = () => {
  const formatTime = value => value.toString().padStart(2, "0");

  const now = new Date();
  const hour = formatTime(now.getHours());
  const minute = formatTime(now.getMinutes());
  const second = formatTime(now.getSeconds());

  return `${hour}:${minute}:${second}`;
};

const setCurrentTimer = timeElements => {
  timeElements.forEach(time => (time.innerHTML = getCurrentTimer()));
};

document.addEventListener("turbo:load", () => {
  currentTimeElements = document.querySelectorAll(".current-time");

  if (currentTimeElements.length) {
    intervalId = setInterval(() => setCurrentTimer(currentTimeElements), 1000);
  } else if (intervalId) {
    clearInterval(intervalId);
    intervalId = null;
  }
});
