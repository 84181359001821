export const Api = {
  getJSON(resource) {
    return fetch(resource, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      }
    });
  }
}