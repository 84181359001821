import { throttle } from "lodash";
import { MAP_ROTATIONS, getSideRotation } from "./map_rotate";

const csrfElement = document.getElementsByName("csrf-token")[0];
if (csrfElement) {
  const token = csrfElement.content;
  const ajaxHeaders = {
    "Content-Type": "application/json",
    Accept: "application/json",
    "X-CSRF-Token": token,
  };
}

function taskTimeout(robotTaskId) {
  const cancel = document.getElementById("cancel-btn");
  const timer = document.getElementById("timer");
  const timerWrapper = document.getElementById("timer-wrapper");
  cancel.disabled = false;
  cancel.classList.remove("btn-outline-danger");
  cancel.classList.add("btn-danger");
  timerWrapper.classList.add("btn-success");
  const timeouts = [];
  timer.innerText = "1";
  for (let i = 1; i < 5; i++) {
    timeouts.push(
      setTimeout(() => {
        timer.innerText = i + 1;
        if (i === 3) {
          timerWrapper.classList.remove("btn-success");
          timerWrapper.classList.add("btn-danger");
        }
      }, 1000 * i)
    );
  }

  const timeout = setTimeout(() => {
    confirmTask(robotTaskId).then(() => {
      location.reload();
    });
  }, 5000);

  cancel.addEventListener("click", () => {
    clearTimeout(timeout);
    timeouts.forEach(t => {
      clearTimeout(t);
    });
    timer.innerText = null;
    cancel.disabled = true;
    cancel.classList.add("btn-outline-danger");
    cancel.classList.remove("btn-danger");
    timerWrapper.classList.remove("btn-success");
    timerWrapper.classList.remove("btn-danger");
    cancelTask(robotTaskId);
  });
}

function initTask() {
  const barcode = document.getElementById("packet-barcode").value;
  // let url = document.getElementById('loading-terminal-scanning').dataset.createTask
  // Rails.ajax({
  //     url: url, type: 'POST',
  //     body: { barcode: barcode } ,
  //     success: function (response) {
  //         taskTimeout(response.robot_task_id)
  //     }
  // })
  createTask(barcode).then(robotTaskId => {
    taskTimeout(robotTaskId);
  });
}

function createTask(barcode) {
  const url = document.getElementById("loading-terminal-scanning").dataset
    .createTask;

  return fetch(`${url}?barcode=${barcode}`, {
    method: "POST",
    credentials: "same-origin",
    headers: ajaxHeaders,
  })
    .then(res => res.json())
    .then(res => res.robot_task_id);
}

function confirmTask(robotTaskId) {
  const url = document.getElementById("loading-terminal-scanning").dataset
    .confirmTask;

  return fetch(`${url}?robot_task_id=${robotTaskId}`, {
    method: "POST",
    credentials: "include",
    headers: ajaxHeaders,
  });
}

function cancelTask(robotTaskId) {
  const url = document.getElementById("loading-terminal-scanning").dataset
    .cancelTask;

  return fetch(`${url}?robot_task_id=${robotTaskId}`, {
    method: "POST",
    credentials: "include",
    headers: ajaxHeaders,
  });
}

function fetchCurrentRobot() {
  const url = document.getElementById("loading-terminal-scanning").dataset
    .robotPath;
  const robotEl = document.getElementById("robot-id");
  if (robotEl.dataset.present === "true") {
    return;
  }

  fetch(url, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  }).then(async response => {
    const data = await response.json();
    robotEl.dataset.present = data.robot_present;
    if (data.robot_present) {
      robotEl.innerHTML = data.content;
      document
        .getElementById("scan-form")
        .querySelectorAll("button")
        .forEach(btn => {
          btn.disabled = false;
        });
    } else {
      robotEl.innerText = data.robot_placeholder;
      document
        .getElementById("scan-form")
        .querySelectorAll("button")
        .forEach(btn => {
          btn.disabled = true;
        });
    }
  });
}

const shiftToServiceAreas = loadingTerminal => {
  const parentRow = loadingTerminal.closest("tr");
  const previousRow = parentRow.previousElementSibling;
  const nextRow = parentRow.nextElementSibling;

  const previousServiceArea =
    previousRow && previousRow.querySelector(".service-area");
  const nextServiceArea = nextRow && nextRow.querySelector(".service-area");

  if (!previousServiceArea || !nextServiceArea) return;

  loadingTerminal.classList.add(
    previousServiceArea ? "shift-end" : "shift-start"
  );
};

export const fixInformationOverlap = rotation => {
  const { TOP, RIGHT, BOTTOM, LEFT } = MAP_ROTATIONS;
  const infoElements = document.querySelectorAll(
    ".loading-terminal-information"
  );
  if (!infoElements.length) return;

  // If no rotation is specified, find rotation in map's classname
  if (!rotation || typeof rotation !== "string") {
    const map = document.querySelector(".map");
    rotation = getSideRotation(map);
  }

  const resetOffsets = element => {
    element.style.transform = "translateX(0)";
    element.style.transform = "translateY(0)";
  };

  // Reset transform offsets (necessary when changing rotation)
  infoElements.forEach(info => {
    resetOffsets(info);
    shiftToServiceAreas(info);
  });

  // Returns "true" if two elements overlap/collide with each other
  const isCollision = (el1, el2) => {
    if (!el1 || !el2) return;

    const domRect1 = el1.getBoundingClientRect();
    const domRect2 = el2.getBoundingClientRect();

    return !(
      domRect1.top > domRect2.bottom ||
      domRect1.right < domRect2.left ||
      domRect1.bottom < domRect2.top ||
      domRect1.left > domRect2.right
    );
  };

  const groupedInfos = [];

  // Groups elements that overlap each other
  for (let i = 0; i < infoElements.length; i++) {
    const info1 = infoElements[i];
    const info2 = infoElements[i + 1];

    // Skip, if info2 doesn't exist (= is last element);
    if (info2 && isCollision(info1, info2)) {
      groupedInfos.push([info1, info2]);
      i++;
    }
  }

  const getInfoLabel = infoElement => {
    const nestChild = infoElement.children[1].children[1];
    if (!nestChild) return;

    return nestChild.innerText;
  };

  // TODO: Implement Unit tests, after we start using them
  groupedInfos.forEach(([element1, element2]) => {
    const label1 = getInfoLabel(element1);
    const label2 = getInfoLabel(element2);
    if (!label1 || !label2) return;

    // In some cases, info-elements order is swapped
    // - sometimes [02] comes before [01]
    const switchUp = label1.slice(-2) > label2.slice(-2);

    // This assignes them in the correct order for clarity (and mental sanity)
    let item1 = switchUp ? element2 : element1;
    let item2 = switchUp ? element1 : element2;

    const translateItemOffsets = (offset, axis) => {
      item1.style.transform = `translate${axis}(${-offset}rem)`;
      item2.style.transform = `translate${axis}(${offset}rem)`;
    };

    const rotationOffsets = (rotation1, rotation2, axis) => {
      const offset = 1.6;

      if (
        (rotation == rotation1 && !switchUp) ||
        (rotation == rotation2 && switchUp)
      ) {
        translateItemOffsets(offset, axis);
      } else {
        translateItemOffsets(-offset, axis);
      }
    };

    if (isCollision(item1, item2)) {
      if (rotation == RIGHT || rotation == LEFT) {
        rotationOffsets(RIGHT, LEFT, "Y");
      } else if (rotation == TOP || rotation == BOTTOM) {
        rotationOffsets(TOP, BOTTOM, "X");
      }
    }
  });
};

document.addEventListener("turbo:load", () => {
  fixInformationOverlap();

  window.addEventListener("resize", throttle(fixInformationOverlap, 500));
});

// import consumer from "../../channels/consumer"
//
// document.addEventListener("turbo:load", function () {
//     let root = document.getElementById('loading-terminal-scanning')
//     if (!root || root.dataset.enabled !== 'true') {
//         return;
//     }
//     let btn = document.getElementById('submit-btn')
//     btn.addEventListener('click', initTask)
//     // setInterval(fetchCurrentRobot, 1000)
//     consumer.subscriptions.create({channel: "RobotChannel", loading_terminal_code: root.dataset.id}, {
//         received(data) {
//             let robotEl = document.getElementById('robot-id')
//             robotEl.dataset.present = data['robot_present']
//             robotEl.innerText = data['current_robot_id']
//             if (data['robot_present']) {
//                 document.getElementById('scan-form').querySelectorAll('button').forEach(function (btn) {
//                     btn.disabled = false
//                 })
//             } else {
//                 document.getElementById('scan-form').querySelectorAll('button').forEach(function (btn) {
//                     btn.disabled = true
//                 })
//             }
//         }
//     })
//
//     consumer.subscriptions.create({channel: "LoadingTerminalChannel", code: root.dataset.id}, {
//         received(data) {
//             console.log(data)
//         }
//     })
// });
