import { Tooltip } from "bootstrap";

function initTooltips() {
  const tooltipTriggerList = [].slice.call(
    document.querySelectorAll("[data-bs-toggle='tooltip']"),
  );
  const tooltipList = tooltipTriggerList.map(tooltipTriggerEl => {
    return new Tooltip(tooltipTriggerEl);
  });
  window.tooltips = tooltipList;
}

document.addEventListener("turbo:load", () => {
  initTooltips();
});

document.addEventListener("turbo:before-cache", () => {
  window.tooltips.forEach(tooltip => {
    tooltip.dispose();
  });
  window.tooltips = [];
});
