import "./index.scss"

import { application } from "../../controllers/application"
import { Controller } from "@hotwired/stimulus"
import { getChoices } from "../../controls/javascripts/choices";
import { Api } from "../../controls/javascripts/utils/api";

class ServiceAreaModal extends Controller {
    static targets = ["select"]
    connect() {
        let el = this
        this.element.addEventListener("show.bs.modal", function (event) {
            Api.getJSON(el.url)
                .then(response => response.json())
                .then(data => el.set_data(data))
        })
    }

    set_data(data) {
        this.choices.clearChoices();
        this.choices.setChoices(data.service_areas)
    }

    get choices() {
        return getChoices(this.selectTarget)
    }

    get url() {
        return this.element.dataset.url
    }
}
application.register("service-area-modal", ServiceAreaModal)
