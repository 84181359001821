import "./index.scss"

import {application} from "../../controllers/application"
import {Controller} from "@hotwired/stimulus"

class ServiceAreaPopover extends Controller {
    static targets = ['withRobot', 'withoutRobot']
    static values = {code: String}

    connect() {
        if(this.hasRobot()){
            this.showRobot()
        }else {
            this.hideRobot()
        }
    }

    showRobot() {
        this.withRobotTarget.classList.remove('d-none')
        this.withoutRobotTarget.classList.add('d-none')
    }

    hideRobot() {
        this.withRobotTarget.classList.add('d-none')
        this.withoutRobotTarget.classList.remove('d-none')
    }

    get td() {
        return document.querySelector(`[data-code="${this.codeValue}"]`)
    }

    hasRobot() {
        return this.td.classList.contains('with-robot')
    }
}

application.register("service-area-popover", ServiceAreaPopover)
