// Some parts of this script are from Official Highcharts Synchronized Charts demo reference:
// https://www.highcharts.com/demo/synchronized-charts
import Highcharts from "highcharts";

const CHART_CHILD_DATA = "data-highcharts-chart";
const START_EVENT_TYPES = ["mousemove", "touchmove", "touchstart"];
const END_EVENT_TYPES = ["mouseleave", "touchcancel", "touchend"];
const defaultPointerReset = Highcharts.Pointer.prototype.reset;

// Highlight points in all charts and their series
const highlightAllPoints = (chart, e, reset) => {
  if (!chart || !e) return;

  const points = [];

  chart.series.forEach(serie => {
    const point = serie.searchPoint(e, true);

    if (point) {
      points.push(point);
      point.onMouseOver();
    }
  });

  if (points.length > 0) {
    chart.tooltip.shared = true; // Show all series values in one tooltip
    chart.tooltip.refresh(points); // Refresh tooltip for all series points
  }

  // If theres only 1 serie in the chart, hide all the points when mouse leaves
  if (reset && chart.series.length < 2) {
    points.forEach(point => point.onMouseOut());
  }
};

// Update pointers and tooltips on every chart instance while moving mouse inside the container
// Reset all pointers and tooltips when mouse leaves the container
const updateChartPointer = (chart, e, reset = false) => {
  if (!chart || !e) return;

  const event = chart.pointer.normalize(e);
  chart.pointer.reset = () => undefined; // Keep points and tooltips visible when hovering over container
  highlightAllPoints(chart, event, reset);

  if (reset) {
    chart.pointer.reset = defaultPointerReset; // Hide points and tooltips when mouse leaves
    chart.tooltip.destroy();
  }
};

// Update all chart instances inside a container based on event types
const synchronizeCharts = (eventTypesArray, container, charts, reset = false) => {
  if (!eventTypesArray || !container || !charts) return;

  eventTypesArray.forEach(eventType => {
    container.addEventListener(eventType, e => {
      charts.forEach(chart => updateChartPointer(chart, e, reset));
    });
  });
};

// Find all chart instances inside a ".highcharts-synchronized" container and synchronize them
export const initializeSynchronizedCharts = container => {
  if (!container) return;

  const childrenCharts = container.querySelectorAll(`[${CHART_CHILD_DATA}]`);
  if (!childrenCharts) return;

  const chartItemsIndexes = [];

  childrenCharts.forEach(child =>
    chartItemsIndexes.push(child.getAttribute(CHART_CHILD_DATA))
  );

  const charts = chartItemsIndexes.map(index => Highcharts.charts[index]);

  // Update all chart children when moving mouse inside the container
  synchronizeCharts(START_EVENT_TYPES, container, charts);
  // Reset all chart children when mouse leaves the container
  synchronizeCharts(END_EVENT_TYPES, container, charts, true);
};

document.addEventListener("turbo:load", () => {
  const synchronizedChartsContainers = document.querySelectorAll(".highcharts-synchronized");
  if (!synchronizeCharts.length) return;
  
  // Synchronize charts inside every ".highcharts-synchronized" container
  synchronizedChartsContainers.forEach(container => {
    if (container.parentElement.getAttribute("data-controller") !== "turbo-issue-186-workaround") {
      initializeSynchronizedCharts(container);
    }
  });
});
