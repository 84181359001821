import { throttle } from "lodash";

const getComputedProperty = (element, property) => {
  if (!element || !property) return;

  return window
    .getComputedStyle(element)
    .getPropertyValue(property);
};

const convertParentPadding = element => {
  if (!element.parentElement) return;

  const paddingLeft = getComputedProperty(element.parentElement, "padding-left");
  const paddingRight = getComputedProperty(element.parentElement, "padding-right");

  element.style.marginLeft = `-${paddingLeft}`;
  element.style.marginRight = `-${paddingRight}`;

  element.style.paddingLeft = paddingLeft;
  element.style.paddingRight = paddingRight;
};

const setStickyElementOffset = (element, offset) => {
  if (!element || !offset) return;
  element.style.top = `${offset}px`;
}

const initializeStickyElements = (shadow = true) => {
  const stickyElements = document.querySelectorAll(".element-sticky");
  if (!stickyElements) return;

  const navbar = document.querySelector(".navbar");
  let lastOffsetHeight = 0;

  if (navbar) {
    lastOffsetHeight += navbar.scrollHeight;
  }

  stickyElements.forEach((stickyElement, index) => {
    convertParentPadding(stickyElement);
    setStickyElementOffset(stickyElement, lastOffsetHeight);

    if (shadow && index === stickyElements.length - 1) {
      stickyElement.classList.add("shadow-bottom");
    }

    lastOffsetHeight += stickyElement.scrollHeight;
  });
};

document.addEventListener("turbo:load", () => {
  const resizeInitialization = throttle(initializeStickyElements, 500, { trailing: true });

  initializeStickyElements();
  window.addEventListener("resize", resizeInitialization);
});
