import "./index.scss"

import {application} from "../../controllers/application"
import {Controller} from "@hotwired/stimulus"

class LoadingTerminalPopover extends Controller {
    static targets = ['activate', 'deactivate', 'user']
    static values = {code: String, inactiveTitle: String, activeTitle: String}

    connect() {
        if(!this.hasUser()){
            return this.showNoUser();
        }
        if(this.isActive()){
            this.deactivate()
        }else {
            this.activate()
        }
    }

    setActiveTitle(){
        this.headerElement.innerHTML = this.activeTitleValue
    }

    setInactiveTitle(){
        this.headerElement.innerHTML = this.inactiveTitleValue
    }

    showNoUser() {
        this.userTarget.classList.remove('d-none')
        this.activateTarget.classList.add('d-none')
        this.deactivateTarget.classList.add('d-none')
    }

    deactivate() {
        this.setActiveTitle()
        this.userTarget.classList.add('d-none')
        this.activateTarget.classList.add('d-none')
        this.deactivateTarget.classList.remove('d-none')
    }

    activate() {
        this.setInactiveTitle()
        this.userTarget.classList.add('d-none')
        this.activateTarget.classList.remove('d-none')
        this.deactivateTarget.classList.add('d-none')
    }

    get td() {
        return document.querySelector(`[data-code="${this.codeValue}"]`)
    }

    get baseElement() {
        return this.element.closest('.popover')
    }

    get headerElement(){
        return this.baseElement.querySelector('.popover-header')
    }

    isActive() {
        return this.td.querySelector(".code").classList.contains('active')
    }

    hasUser() {
        return this.td.querySelector(".user i").classList.contains('active')
    }
}

application.register("loading-terminal-popover", LoadingTerminalPopover)
