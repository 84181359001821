const CLASS = {
  REBOUND: "rebound",
};

const VALUE = {
  MAP_HEADER_WIDTH: "1.5rem",
};

document.addEventListener("turbo:load", () => {
  const printPage = document.querySelector("body.pdf_print");
  const mapToPrint = document.querySelector("body.map-to-print");

  if (!mapToPrint) return;

  const infoBar = mapToPrint.querySelector(".info-bar");

  if (infoBar) {
    mapToPrint.classList.add("info-bar-enable");
    infoBarStyles(mapToPrint);
  }

  mapStyles(mapToPrint);

  setTimeout(() => {
    printPage && printStyles(printPage);
  }, 100);
});

const mapStyles = mapToPrint => {
  const fingerNumber = mapToPrint.querySelector(".finger-number-print");
  if (!fingerNumber) return;
  const firstTr = fingerNumber.parentNode;
  const allThinFingers = firstTr.querySelectorAll(".thin-finger");

  allThinFingers.forEach(finger => {
    finger.previousElementSibling.style.width = VALUE.MAP_HEADER_WIDTH;
    finger.nextElementSibling.style.width = VALUE.MAP_HEADER_WIDTH;
  });
};

const printStyles = printPage => {
  const style = document.createElement("style");
  const [landscape, portrait, auto] = ["landscape", "portrait", "auto"].map(
    size => `@page { size: ${size}; }`
  );
  const [printLandscape, printPortrait] = [
    ".width-a4-landscape",
    ".width-a4-portrait",
  ].map(size => printPage.querySelector(size));

  style.media = "print";
  style.innerHTML = printLandscape
    ? landscape
    : printPortrait
    ? portrait
    : auto;

  document.head.appendChild(style);
  window.print();
};

const infoBarStyles = mapToPrint => {
  const allChuteRoutings = [];
  const filteredInfoBarsByDataX = [];

  fillAllChuteRoutings(mapToPrint, allChuteRoutings);
  setInfoBarHeigth(allChuteRoutings);
  fillFilteredInfoBarsByDataX(allChuteRoutings, filteredInfoBarsByDataX);

  rebound(filteredInfoBarsByDataX);

  setChuteRoutingStyles(allChuteRoutings);
};

const setChuteRoutingStyles = allChuteRoutings => {
  const groupedByDataX = [];
  const allDataX = allChuteRoutings.map(a => a.dataX);
  const filteredDataX = [...new Set(allDataX)];

  filteredDataX.forEach(number => {
    const filterElementsByDataX = allChuteRoutings.filter(obj => {
      return obj.dataX === number;
    });

    const group = {
      dataX: number,
      routings: filterElementsByDataX,
    };

    groupedByDataX.push(group);
  });

  groupedByDataX.forEach(group => {
    const groupedRoutings = [];
    const rightRoutings = [];
    const leftRoutings = [];

    group.routings.forEach((routing, index) => {
      const getProperties = routingSide => {
        const lenAttribute = routingSide.len;

        if (lenAttribute < 1) return;

        const statusColor = routingSide.statusColor;
        const properties = {
          index: index,
          len: lenAttribute,
          color: statusColor,
          arrow: routingSide.arrow,
          chuteCode: routingSide.chuteCode,
          wrapper: routingSide.wrapper,
        };

        return properties;
      };

      routing.rightRouting &&
        rightRoutings.push(getProperties(routing.rightRouting));

      if (routing.leftRouting) {
        leftRoutings.push(getProperties(routing.leftRouting));
        if (routing.leftRouting.infoBar.classList.contains("rebound")) {
          leftRoutings[0].rebound = true;
        }
      }
    });

    groupedRoutings.push(rightRoutings);
    groupedRoutings.push(leftRoutings);

    groupedRoutings.forEach(group => {
      const infoBarIndexes = [];

      group.forEach((routing, index) => {
        infoBarproperties = {
          index: index,
          color: routing.color,
        };

        if (routing.len > 0) infoBarIndexes.push(infoBarproperties);
      });

      let longerChuteCode = 0;
      let lastColor;
      let lastItemLen;
      let hasRebound = false;

      for (const item of group) {
        if (item.chuteCode) {
          const chuteLen = item.chuteCode.innerText.length;

          if (chuteLen > longerChuteCode) {
            longerChuteCode = chuteLen;
          }
        }

        if (item.color && item.len) {
          lastColor = item.color;
          lastItemLen = item.len - 1;
        } else if (lastItemLen > 0) {
          lastItemLen--;
          item.color = lastColor;
        }

        if (item.rebound) {
          hasRebound = true;
        }
      }

      group.forEach(routing => {
        routing.arrow && routing.arrow.classList.add(`${routing.color}-text`);
        routing.chuteCode &&
          routing.chuteCode.classList.add(`width-${longerChuteCode}ch`);
        if (!hasRebound) {
          routing.wrapper.classList.add("without-rebound");
        }
      });
    });
  });
};

const fillAllChuteRoutings = (mapToPrint, allChuteRoutings) => {
  const chuteRoutings = mapToPrint.querySelectorAll(".chute-routings");

  if (!chuteRoutings.length) return;

  chuteRoutings.forEach(td => {
    const individualRoutings = td.querySelectorAll(".chute-routing");
    let leftRouting;
    let rightRouting;
    const axis = xy => {
      return td.getAttribute(`data-${xy}`);
    };

    individualRoutings.forEach(routing => {
      const arrow = routing.querySelector("[class*=fa-arrow-]");

      const chuteCode = routing.querySelector(".chute-code");
      const infoBar = routing.querySelector(".info-bar");
      const infoBarStatusColor = infoBar.getAttribute("status-color");
      const routingObject = {
        arrow: arrow,
        infoBar: infoBar,
        statusColor: infoBarStatusColor,
        chuteCode: chuteCode,
        wrapper: routing,
      };

      routing.classList.contains("left")
        ? (leftRouting = routingObject)
        : (rightRouting = routingObject);
    });

    const routingObject = {
      element: td,
      dataX: axis("x"),
      dataY: axis("y"),
      leftRouting: leftRouting,
      rightRouting: rightRouting,
    };

    allChuteRoutings.push(routingObject);
  });

  allChuteRoutings.sort((a, b) => a.dataX - b.dataX);
};

const fillFilteredInfoBarsByDataX = (
  allChuteRoutings,
  filteredInfoBarsByDataX
) => {
  const allDataX = allChuteRoutings.map(a => a.dataX);
  const filteredDataX = [...new Set(allDataX)];

  filteredDataX.forEach(number => {
    const leftRoutings = [];
    const rightRoutings = [];
    const filterElementsByDataX = allChuteRoutings.filter(obj => {
      return obj.dataX === number;
    });
    const getRouting = (side, rightRoutings, leftRoutings) => {
      filterElementsByDataX.forEach(element => {
        const infoBar =
          side === "right" ? element.rightRouting : element.leftRouting;

        if (!infoBar || !infoBar.len) return;

        side === "right"
          ? rightRoutings.push(infoBar)
          : leftRoutings.push(infoBar);
      });
    };
    getRouting("right", rightRoutings, leftRoutings);
    getRouting("left", rightRoutings, leftRoutings);

    const groupedElements = {
      dataX: number,
      rightRoutings: rightRoutings,
      leftRoutings: leftRoutings,
    };

    filteredInfoBarsByDataX.push(groupedElements);
  });
};

const setInfoBarHeigth = allChuteRoutings => {
  const tdHeight = allChuteRoutings[0].element.offsetHeight;

  allChuteRoutings.forEach(obj => {
    const setHeigth = side => {
      if (!side) return;
      const infoBar =
        side === obj.rightRouting
          ? obj.rightRouting.infoBar
          : obj.leftRouting.infoBar;

      const infoBarBorder = infoBar.querySelector("[len]");
      const lenNumber = infoBarBorder.getAttribute("len");

      if (lenNumber < 1) return;

      const finalHeight = lenNumber * tdHeight;
      const shortRoute = lenNumber < 2 ? true : false;

      infoBarBorder.style.height = `${finalHeight}px`;
      side.shortRoute = shortRoute;
      side.len = lenNumber;
    };

    setHeigth(obj.rightRouting);
    setHeigth(obj.leftRouting);
  });
};

const rebound = arr => {
  arr.forEach(obj => {
    const reboundRoutings = chuteRoutes => {
      const firstChute = chuteRoutes[0];
      const secondChute = chuteRoutes[1];

      chuteRoutes.forEach(route => {
        const index = chuteRoutes.findIndex(x => x.infoBar === route.infoBar);
        const previousEl = chuteRoutes[index - 1];
        const nextEl = chuteRoutes[index + 1];
        const addRebound = condition => {
          if (condition) {
            route.infoBar.classList.add(CLASS.REBOUND);
          }
        };

        addRebound(route.shortRoute && route.infoBar !== firstChute.infoBar);
        secondChute &&
          addRebound(
            route.infoBar === secondChute.infoBar && firstChute.shortRoute
          );

        if (!previousEl) return;

        if (
          previousEl.infoBar.classList.contains(CLASS.REBOUND) ||
          !previousEl.shortRoute
        ) {
          route.infoBar.classList.remove(CLASS.REBOUND);
        }

        if (!nextEl) return;

        addRebound(
          route.shortRoute && !previousEl.shortRoute && !nextEl.shortRoute
        );
      });
    };

    reboundRoutings(obj.rightRoutings);
    reboundRoutings(obj.leftRoutings);
  });
};
