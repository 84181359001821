// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
import "@hotwired/turbo";
import "channels";
import Chartkick from "chartkick";
import Highcharts from "highcharts";
import chartExporting from "highcharts/modules/exporting";
import chartDataExporting from "highcharts/modules/export-data";
import highchartsSankey from "highcharts/modules/sankey";
import "cocoon-js-vanilla";

chartExporting(Highcharts);
chartDataExporting(Highcharts);
highchartsSankey(Highcharts);
Highcharts.setOptions({
  exporting: {
    enabled: false,
    buttons: {
      contextButton: {
        menuItems: [
          "viewFullscreen",
          "printChart",
          "downloadCSV"
        ]
      }
    }
  }
});
window.Highcharts = Highcharts;
Chartkick.use(Highcharts);

require.context("../images", true);

Rails.start();
import "flatpickr/dist/flatpickr";
require("flatpickr/dist/l10n/cs.js");
import "bootstrap";
require("mdb-ui-kit/js/mdb.min.js");
require("@fortawesome/fontawesome-pro");
require("choices.js");
require("choices.js/public/assets/styles/choices.min.css");
import "../vendor/gems.js";
require("flatpickr/dist/themes/material_blue.css");
import "controllers";
import "controls";
import "components";
