document.addEventListener("turbo:load", () => {
  alertTimer();
});

const alertTimer = () => {
  const alertElements = document.querySelectorAll(".alert");
  if (alertElements.length < 1) return;
  const DELAY = 10000;

  alertElements.forEach(element => {
    const SHOW = "show";
    const timeout = () => {
      setTimeout(() => {
        element.classList.remove(SHOW);
      }, DELAY);
    };

    element.classList.contains(SHOW) && timeout();
  });
};
