function bindDataLink() {
  document.querySelectorAll("[data-link]").forEach(node => {
    node.addEventListener("dblclick", event => {
      if (!event.target.matches(".dropdown-toggle, .dropdown-menu a, .dropdown-menu li, .dropdown-toggle span")) {
        if (node.dataset.turbolinksLink === false) {
          document.location.href = node.dataset.link;
        } else {
          document.querySelector("#index_sidebar") && document.querySelector("#index_sidebar").classList.remove("show");
          window.Turbo.visit(node.dataset.link);
        }
      }
    });

    node.addEventListener("auxclick", event => {
      if (event.button === 1) {
        //Middle click
        if (!event.target.matches(".dropdown-toggle, .dropdown-menu a, .dropdown-menu li, .dropdown-toggle span")) {
          window.open(node.dataset.link, "_blank");
        }
      }
    });
  });
}

window.bindDataLink = bindDataLink;

document.addEventListener("turbo:load", () => {
  bindDataLink();
});
