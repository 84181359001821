import Choices from "choices.js";
import { Api } from "./utils/api";

export const DefaultChoicesOptions = {
  maxItemCount: 1,
  addItems: true,
  noResultsText: "No results found",
  noChoicesText: "No results found",
  itemSelectText: "",
  shouldSort: false,
  removeItemButton: true,
};

const choicesSelector = `select.select2:not(.filter-select-many, 
   .filter-select-one, .debug), 
   select.select2_tags, 
   select.grouped_select2`;

document.addEventListener("turbo:render", () => {
  handleChoicesErrors();
});

document.addEventListener("turbo:load", () => {
  togglePlaceholder();
  initChoices();

  document.querySelectorAll("div.links").forEach(select => {
    select.addEventListener("cocoon:after-insert", () => {
      initChoices();
    });
  });
});

// Caching page on initial turbo:load event causes troubles with proper
// displaying of Choices instances.
document.addEventListener(
  "turbo:load",
  () => {
    const elementsToCheck =
      "select.select2, select.select2_tags, select.grouped_select2";
    const anyChoicesOnPage = document.querySelectorAll(elementsToCheck);
    if (!anyChoicesOnPage.length) return;

    const headTag = document.querySelector("head");
    const metaTag = document.createElement("meta");

    metaTag.name = "turbo-cache-control";
    metaTag.content = "no-cache";

    headTag.appendChild(metaTag);
  },
  { once: true }
);

document.addEventListener("turbo:frame-load", event => {
  const frameChoicesSelects = event.target
    .closest("turbo-frame")
    .querySelectorAll(choicesSelector);

  frameChoicesSelects.forEach(select => {
    addChoices(new Choices(select, choicesOptions(select)));
  });
});

document.addEventListener("turbo:before-cache", () => {
  destroyChoicesInstances();
});

document.addEventListener("turbo:submit-start", event => {
  const inTurboFrame = event.target.closest("turbo-frame");

  if (inTurboFrame) {
    const frameChoicesSelects = inTurboFrame.querySelectorAll(choicesSelector);

    frameChoicesSelects.forEach(select => {
      window.choices = window.choices.filter(choices => {
        return choices.passedElement.element !== select;
      });
      delete window.choicesMap[select.dataset.choicesID];
    });

    return;
  }

  destroyChoicesInstances();

  // after form submission, turbo doesn't trigger turbo:load event
  document.addEventListener(
    "turbo:render",
    () => {
      togglePlaceholder();
      initChoices();
    },
    { once: true }
  );
});

const initChoices = () => {
  const select = document.querySelectorAll(choicesSelector);

  select.forEach(el => {
    if (el.dataset.choicesID) return;

    const choice = new Choices(el, choicesOptions(el));
    addChoices(choice);
  });

  document
    .querySelectorAll(
      "select.select2_ajax:not(.regions, .filter-select-many, .filter-select-one)"
    )
    .forEach(select => {
      initAjaxChoices(select);
    });
};

export const choicesOptions = element => {
  const { dataset, attributes } = element;
  let maxItemCount = attributes["multiple"] ? -1 : 1;

  if (attributes["multiple"] && dataset.maxItemsCount) {
    maxItemCount = dataset.maxItemsCount;
  }

  const customOptions = {
    maxItemCount,
  };

  if (dataset.noResults) {
    ["noChoicesText", "noResultsText"].forEach(option => {
      customOptions[option] = dataset.noResults;
    });
  }

  if (dataset.maxItemText) {
    customOptions["maxItemText"] = maxItemCount => {
      return dataset.maxItemText.replace("${maxItemCount}", maxItemCount);
    };
  }

  return { ...DefaultChoicesOptions, ...customOptions };
};

export const addChoices = choice => {
  const { dataset } = choice.passedElement.element;

  if (!window.choices) {
    window.choices = [];
  }

  if (!window.choicesMap) {
    window.choicesMap = {};
  }

  if (!dataset.choicesID) {
    dataset.choicesID = generateRandomID();
  }

  window.choicesMap[dataset.choicesID] = choice;
  window.choices.push(choice);

  return choice;
};

const initAjaxChoices = select => {
  const searchUrl = select.dataset.searchurl;
  const ajaxOptions = {
    searchResultLimit: 15,
    searchChoices: false,
  };
  const myChoice = new Choices(select, {
    ...choicesOptions(select),
    ...ajaxOptions,
  });

  addChoices(myChoice);

  let myTimeOut;

  select.addEventListener("search", event => {
    clearTimeout(myTimeOut);

    const urlConnector = searchUrl.includes("?") ? "&" : "?";
    const fetchUrl = `${searchUrl}${urlConnector}query=${
      event.detail.value
    }&limit=${15}`;

    myTimeOut = setTimeout(() => {
      myChoice.setChoices(async () => {
        const response = await Api.getJSON(fetchUrl);
        const { data } = await response.json();

        myChoice.clearChoices();

        return data;
      });
    }, 300);
  });
};

export const generateRandomID = () => {
  // https://gist.github.com/gordonbrander/2230317
  return `_${Math.random().toString(36).substring(2, 11)}`;
};

const togglePlaceholder = () => {
  const selects = document.querySelectorAll("form .select2");
  const PLACEHOLDER_CLASS = "hide-placeholder";

  selects.forEach(select => {
    ["addItem", "removeItem"].forEach(eventType => {
      select.addEventListener(eventType, ({ target }) => {
        const { length, parentElement } = target;
        const shouldContinue =
          (eventType === "addItem" && length) ||
          (eventType === "removeItem" && !length);

        if (!shouldContinue) return;

        parentElement.classList.toggle(PLACEHOLDER_CLASS, !!length);
      });
    });
  });
};

const destroyChoicesInstances = () => {
  if (window.choices) {
    window.choices.forEach(choices => {
      choices.destroy();
    });

    window.choices = [];
  }

  if (window.choicesMap) {
    window.choicesMap = {};
  }
};

// This function handles proper displaying of element with error message
// on Choices input after form submit
const handleChoicesErrors = () => {
  const errorStatements = document.querySelectorAll(".invalid-feedback");

  errorStatements.forEach(errorStatement => {
    const choiceContainer = errorStatement.previousSibling;
    if (!choiceContainer || !choiceContainer.classList.contains("choices"))
      return;

    errorStatement.classList.add("d-block");
    choiceContainer.classList.add("mb-0");
    choiceContainer.firstChild.classList.add("form-control", "is-invalid");
  });
};

export const getChoices = element => {
  const { choicesID } = element.dataset;

  return choicesID && window.choicesMap[choicesID];
};
