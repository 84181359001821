const changeTooltips = () => {
  const normalTooltips = document.querySelectorAll(
    "[data-toggle='tooltip']:not([data-bs-toggle='tooltip']), .breadcrumb-item [title]",
  );

  normalTooltips.forEach(tooltip => {
    tooltip.removeAttribute("data-toggle");
    tooltip.setAttribute("data-bs-toggle", "tooltip");
  });
};

document.addEventListener("turbo:load", () => {
  changeTooltips();
});
