const CONSTANTS = {
  ROW_HEIGHT: "31",
  WIDTH_LANDSCAPE: "width-a4-landscape",
  WIDTH_PORTRAIT: "width-a4-portrait",
  LEFT: "left",
  RIGHT: "right",
};

document.addEventListener("turbo:load", () => {
  const mapPrintSorter = document.querySelector(".map-print-sorter");
  if (!mapPrintSorter) return;

  const ptlRoutings = mapPrintSorter.querySelectorAll(".chute.ptl-routings");
  const chutes = mapPrintSorter.querySelectorAll(".chute");
  const columnIndexes = mapPrintSorter.querySelectorAll("[class*=index-]");
  const sorterLetters = mapPrintSorter.querySelectorAll(".sorter-letter");

  if (!ptlRoutings.length) {
    reorderSorterColumns(mapPrintSorter, columnIndexes);

    return;
  }

  chutes.forEach(chute => {
    if (!containClass(chute, "ptl-routings")) {
      removeClasses(chute, "justify-content-between");
    }

    addClasses(chute, "width-230px");
  });

  changePageWidth(mapPrintSorter, columnIndexes);
  copyHeader(mapPrintSorter);
  setRouteSpanHeight(mapPrintSorter);
  reorderColumns(mapPrintSorter);
  reorderSorterLetters(sorterLetters, columnIndexes);

  ptlRoutings.forEach(routing => {
    const routeSpans = routing.querySelectorAll(".route-span");
    if (routeSpans.length < 2) return;

    setMultipleRouteSpans(routeSpans, routing);
  });
});

// Remove function for classes after add FE kit to PA
const addClasses = (element, ...classes) => element.classList.add(...classes);
const removeClasses = (element, ...classes) =>
  element.classList.remove(...classes);
const swapClass = (element, firstClass, secondClass) => {
  removeClasses(element, firstClass);
  addClasses(element, secondClass);
};
const containClass = (element, myClass) => element.classList.contains(myClass);

const reorderSorterLetters = (sorterLetters, columnIndexes) => {
  const letterA = sorterLetters[0];
  const letterB = sorterLetters[1];
  const secondColumn = columnIndexes[1];
  const fourthColumn = columnIndexes[3];

  secondColumn.appendChild(letterA);
  fourthColumn.appendChild(letterB);

  sorterLetters.forEach(letter => {
    addClasses(letter, "position-absolute", "start-0", "top-0");
  });
};

const setMultipleRouteSpans = (routeSpans, routing) => {
  addClasses(routeSpans[0], "top-n02r");
  addClasses(routeSpans[1], "top-08r");

  routeSpans.forEach(span => {
    const icons = span.querySelector(".route-span-icons");

    addClasses(span, "route-span-multiple");
    removeClasses(icons, "flex-column");
    containClass(routing, "left") && addClasses(icons, "flex-row-reverse");
  });
};

const copyHeader = mapPrintSorter => {
  const sorterHeader = mapPrintSorter.querySelector(".map-print-sorter-header");
  const cloneHeader = sorterHeader.cloneNode(true);
  const secondIndex = mapPrintSorter.querySelector(".index-1");
  const parentEl = secondIndex.parentNode;

  addClasses(cloneHeader, "pt-2", "px-0");
  parentEl.insertBefore(cloneHeader, secondIndex.nextSibling);
};

const reorderColumns = mapPrintSorter => {
  const [firstColumn, secondColumn, thirdColumn, fourthColumn] = [
    ".index-0",
    ".index-1",
    ".index-2",
    ".index-3",
  ].map(index => mapPrintSorter.querySelector(index));
  const wrapper = firstColumn.parentNode;

  wrapper.insertBefore(secondColumn, firstColumn);
  wrapper.insertBefore(fourthColumn, thirdColumn);
  swapRoutingsColumns(secondColumn);
};

const swapRoutingsColumns = parentColumn => {
  const firstColumn = parentColumn.querySelectorAll(".routings-column")[0];
  const secondColumn = parentColumn.querySelectorAll(".routings-column")[1];

  firstColumn.parentNode.insertBefore(secondColumn, firstColumn);
  removeClasses(firstColumn, CONSTANTS.LEFT);
  removeClasses(secondColumn, CONSTANTS.RIGHT);
  addClasses(firstColumn, CONSTANTS.RIGHT);
  addClasses(secondColumn, CONSTANTS.LEFT);
  firstColumn.firstChild.style.marginTop = `${CONSTANTS.ROW_HEIGHT}px`;
  changeRoutingsColumnsFlex(firstColumn);
  changeRoutingsColumnsFlex(secondColumn);
};

const changeRoutingsColumnsFlex = column => {
  const chutes = column.querySelectorAll(".chute");

  chutes.forEach(chute => {
    containClass(chute, CONSTANTS.RIGHT)
      ? swapClass(chute, CONSTANTS.RIGHT, CONSTANTS.LEFT)
      : swapClass(chute, CONSTANTS.LEFT, CONSTANTS.RIGHT);
  });
};

const setRouteSpanHeight = mapPrintSorter => {
  const routeSpans = mapPrintSorter.querySelectorAll(".route_span");
  if (!routeSpans.length) return;

  routeSpans.forEach(route => {
    const rowSpanLen = route.getAttribute("row-span");
    const newHeigth = `${CONSTANTS.ROW_HEIGHT * rowSpanLen}px`;

    route.style.height = newHeigth;
  });
};

const changePageWidth = (mapPrintSorter, columnIndexes) => {
  mapPrintSorter.classList.add("ptl-routings");

  const landscapeElements = document.querySelectorAll(
    `.${CONSTANTS.WIDTH_LANDSCAPE}`
  );

  landscapeElements.forEach(el => {
    removeClasses(el, CONSTANTS.WIDTH_LANDSCAPE);
    addClasses(el, CONSTANTS.WIDTH_PORTRAIT);
  });

  columnIndexes.forEach((column, index) => {
    removeClasses(column, "col-auto");
    addClasses(column, "col-12");

    index === 0 && addClasses(column, "page-break", "mt-5");
    index === 3 && addClasses(column, "center-last-index");
    addClasses(column, "justify-content-center");
  });
};

const reorderSorterColumns = (mapPrintSorter, columnIndexes) => {
  let overflowIndex;

  columnIndexes.forEach((column, index) => {
    const chuteCodes = column.querySelectorAll(".chute-code");

    chuteCodes.forEach(code => {
      if (code.textContent === "B101") {
        overflowIndex = index;
        addClasses(code.parentNode, "flex-row-reverse");
      }
    });
  });

  const oveflowColumn = mapPrintSorter.querySelector(
    `.col-auto.index-${overflowIndex}`
  );
  const secondColumn = mapPrintSorter.querySelector(".col-auto.index-1");
  const columnsWrapper = secondColumn.querySelector(".columns-wrapper");

  secondColumn.insertBefore(oveflowColumn, columnsWrapper.nextSibling);
  addClasses(secondColumn, "flex-column", "justify-content-between");
  removeClasses(columnsWrapper, "col-11");
};
