import Highcharts from "highcharts";

const CHART_CHILD_DATA = "data-highcharts-chart";

const findChartInstances = chartsContainer => {
  const chartElements = chartsContainer.querySelectorAll(`[${CHART_CHILD_DATA}]`);
  if (!chartElements) return;

  const charts = [];

  chartElements.forEach(element => {
    const chartId = element.getAttribute(CHART_CHILD_DATA);
    charts.push(Highcharts.charts[chartId]);
  });

  return charts;
};

const refreshChart = chart => {
  if (!chart) return;

  chart.redraw();
  chart.reflow();
};

const initializeChartMenu = () => {
  const chartsContainers = document.querySelectorAll(".highcharts-menu");
  if (!chartsContainers) return;

  const charts = [];

  chartsContainers.forEach(container => {
    const chartInstances = findChartInstances(container);
    charts.push(...chartInstances);
  });

  charts.forEach(chart => {
    chart.options.exporting.enabled = true;
    refreshChart(chart);
  });
};

document.addEventListener("turbo:load", initializeChartMenu);
