import { Turbo } from "@hotwired/turbo-rails";
import flatpickr from "flatpickr";
import consumer from "../../channels/consumer";

function createStatisticConsumer(){
  consumer.subscribeTo(
      { channel: "StatisticsChannel" },
      {
        received(data) {
          data.refresh && window.location.reload();
        },
      },
  );
}


document.addEventListener("turbo:load", () => {
  const el = document.querySelector("#statistics_date_picker");
  if (!el) return;

  const { startsAt = Date.now(), endsAt = Date.now() } = el.dataset;
  
  const getConvertedDate = dateString => {
    const [ day, month, year, hours, minutes ] = dateString.split(/[ |\.|\:]/g);
    return new Date(
      year.length < 4 ? `20${year}` : year,
      month - 1,
      day,
      hours,
      minutes
    );
  };

  flatpickr(el, {
    dateFormat: "d.m.Y H:i",
    enableTime: true,
    time_24hr: true,
    mode: "range",
    defaultDate: [getConvertedDate(startsAt), getConvertedDate(endsAt)],
    locale: {
      firstDayOfWeek: 1
    },
    onClose: selectedDates => {
      const separator = el.dataset.url.includes("?") ? "&" : "?";
      if (selectedDates.length < 2) return;
      const starts_at = flatpickr.formatDate(selectedDates[0], "Y-m-d H:i");
      const ends_at = flatpickr.formatDate(selectedDates[1], "Y-m-d H:i");
      Turbo.visit(
        `${el.dataset.url}${separator}starts_at=${starts_at}&ends_at=${ends_at}`
      );
    }
  });
  createStatisticConsumer();
});
