import { Controller } from "@hotwired/stimulus";
export default class extends Controller {
  static targets = ["map", "levelInfoBar"];

  declare readonly mapTarget: HTMLTableElement;
  declare readonly levelInfoBarTarget: HTMLElement;

  connect() {
    this.setLevelInfoBarWidth();
    this.setMutationObserverForDOM();
  }

  setLevelInfoBarWidth() {
    const rowsWithCells = findRowsWithCells(
      this.mapTarget.querySelectorAll("tr")
    );
    const maxRowY = Math.max(...rowsWithCells.map(row => +row.dataset.y));
    const cellSize = this.mapTarget.querySelector("td").clientWidth;
    const chuteWrapperSize = findChuteWrapperWidth(rowsWithCells);
    const mapWidth = maxRowY * cellSize + chuteWrapperSize;

    this.levelInfoBarTarget.style.width = `${mapWidth}px`;
  }

  setMutationObserverForDOM() {
    const config = { childList: true };
    const composeObserver = new MutationObserver(mutations => {
      mutations.forEach(() => {
        this.setLevelInfoBarWidth();
      });
    });
  
    composeObserver.observe(this.element, config);
  };
}

const findChuteWrapperWidth = (rows: HTMLTableRowElement[]): number => {
  const rowWithChuteWrapper = rows.find(row =>
    row.querySelector(".chute-wrapper")
  );

  return rowWithChuteWrapper.querySelector(".chute-wrapper").clientWidth;
};

const findRowsWithCells = (
  rows: NodeListOf<HTMLTableRowElement>
): HTMLTableRowElement[] => {
  return [...rows].filter(row => row.querySelector("td"));
};

