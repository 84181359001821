import { Turbo } from "@hotwired/turbo-rails";
import Choices from "choices.js";
import { DefaultChoicesOptions } from "./choices";

document.addEventListener("turbo:load", () => {
  initChoices();
  initQrToggle();
});

const initChoices = () => {
  const selects = document.querySelectorAll(".select2.debug");
  if (!selects) return;

  selects.forEach(select => {
    const choices = new Choices(select, DefaultChoicesOptions);
    const { element } = choices.passedElement;
    const { pathname, search } = window.location;

    document.addEventListener("turbo:before-cache", () => {
      choices.destroy();
    });

    fixOptions(choices, search);

    element.addEventListener("addItem", () => {
      const urlParameter = element.name;
      const value = choices.getValue(true);

      const makeUrl = (params, deleteParams = false) => {
        params.set(urlParameter, value);

        deleteParams && params.delete("show_qr");

        return `${pathname}?${params.toString()}`;
      };

      const newUrl = element.classList.contains("pier-id")
        ? makeUrl(new URLSearchParams())
        : element.classList.contains("level-id")
          && makeUrl(new URLSearchParams(search), true);

      if (!newUrl) return;

      Turbo.visit(newUrl);
    });
  });
};

const fixOptions = (choices, search) => {
  const params = new URLSearchParams(search);
  const { element } = choices.passedElement;

  for (const [key, value] of params) {
    if (element.getAttribute("name") === key) {
      choices.setChoiceByValue(value);
    }
  }
};

const initQrToggle = () => {
  const toggle = document.querySelector(".show-qr");
  if (!toggle) return;

  const urlParameter = toggle.name;
  const { pathname, search } = window.location;
  const params = new URLSearchParams(search);

  fixToggle(toggle, params);

  toggle.addEventListener("click", () => {
    toggle.checked
      ? params.set(urlParameter, toggle.value)
      : params.delete(urlParameter);

    const newUrl = `${pathname}?${params.toString()}`;

    Turbo.visit(newUrl);
  });
};

const fixToggle = (toggle, params) => {
  toggle.checked = params.has("show_qr");
};
