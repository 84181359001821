import { fixInformationOverlap } from "./loading_terminals";

const MARKED = "btn-primary";
const OTHER = "btn-outline-secondary";
const MAP_ROTATE_START = "map-rotate-";

export const MAP_ROTATIONS = {
  TOP: "top",
  RIGHT: "right",
  BOTTOM: "bottom",
  LEFT: "left"
};

const getRotateBtn = side => {
  return document.querySelector(`.rotate-${side}`);
};

const switchClass = (el, classToAdd, classToRemove) => {
  const btn = getRotateBtn(el);
  if (!btn) return;
  btn.classList.add(classToAdd);
  btn.classList.remove(classToRemove);
};

const setMapRotation = (map, addSide, removeSides) => {
  const btn = getRotateBtn(`${addSide}`);
  if (!btn) return;
  btn.addEventListener("click", () => {
    map.classList.add(`${MAP_ROTATE_START}${addSide}`);
    switchClass(addSide, MARKED, OTHER);

    setTimeout(() => {
      fixInformationOverlap(addSide);
    }, 50);

    removeSides.forEach(side => {
      map.classList.remove(`${MAP_ROTATE_START}${side}`);
      switchClass(side, OTHER, MARKED);
    });
  });
};

export const getSideRotation = ({ classList }) => {
  if (!classList) return;

  const classArray = [...classList];

  const rotateClass = classArray.find(className => {
    return className.startsWith(MAP_ROTATE_START);
  });

  return rotateClass && rotateClass.split(MAP_ROTATE_START).at(1);
}

const rotateMaps = maps => {
  maps.forEach(map => {
    let sideRotation = getSideRotation(map);
    if (!sideRotation) return;

    switchClass(sideRotation, MARKED, OTHER);

    setMapRotation(map, "top", ["right", "bottom", "left"]);
    setMapRotation(map, "right", ["top", "bottom", "left"]);
    setMapRotation(map, "bottom", ["right", "top", "left"]);
    setMapRotation(map, "left", ["top", "bottom", "right"]);
  });
};

const successConfigMapRotate = () => {
  const mapConfigForms = document.querySelectorAll("form.edit_pier");

  mapConfigForms.forEach(form => {
    const successBtn = form.querySelector("input.btn-success");

    successBtn.addEventListener("click", () => {
      setTimeout(() => {
        location.reload();
      }, 1000);
    });
  });
};

const hideSoloBtnGroup = () => {
  const viewBtnsGroup = document.querySelector(".btn-group-map-views");
  const rotationBtnsGroup = document.querySelector(".btn-group-map-rotations");
  if(!rotationBtnsGroup)
    return;
  const rotationBtns = rotationBtnsGroup.querySelectorAll(".btn");
  const hideIfSingleOrNone = (btns, group) => {
    if (btns.length <= 1) {
      group.classList.add("d-none");
    }
  };

  hideIfSingleOrNone(rotationBtns, rotationBtnsGroup);

  // Because map-confing hasn't view buttons
  if (viewBtnsGroup) {
    const viewBtns = viewBtnsGroup.querySelectorAll(".btn");
    hideIfSingleOrNone(viewBtns, viewBtnsGroup);
  }
};

document.addEventListener("turbo:load", () => {
  const maps = document.querySelectorAll(".map");
  if (maps.length === 0) return;
  rotateMaps(maps);
  successConfigMapRotate();
  hideSoloBtnGroup();
});
