document.addEventListener("turbo:load", () => {
  const mapWrapperForOnly4k = document.querySelector(".map-wrapper.only-4k");
  if (!mapWrapperForOnly4k) return;

  const map = document.querySelector(".map");
  const windowScreen = window.innerWidth;

  if (windowScreen < 3800) {
    map.classList.add("d-none");
    mapWrapperForOnly4k.innerHTML = `<h1>Otevřete mapu na monitoru se 4K rozlišením.</h1>`;
  }
});
