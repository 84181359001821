import { debounce } from "lodash";

const LISTENER_TYPES = {
  CHANGE: "change",
  INPUT: "input",
};

const PRINT_FORMS = {
  DEFAULT: "print_form",
  PRINTER: "print_form_printer",
  PIER: "print_form_pier",
  NUMBER_OF_COPIES: "print_form_number_of_copies",
  PTL_CHUTE: "print_form_ptl_chute",
  PTL_AT_CHUTE: "print_form_ptl_at_chute",
  PICKUP_POINT_ROUTE: "print_form_pickup_point_route_chute",
};

const PARAMS = {
  AUTH_TOKEN: "authenticity_token=",
  PTL_CHUTE: "print_form%5Bptl_chute%5D=",
  PTL_AT_CHUTE: "print_form%5Bptl_at_chute%5D=",
  PICKUP_POINT_ROUTE: "print_form%5Bpickup_point_route_chute%5D=",
};

document.addEventListener("turbo:load", () => {
  const formId = document.getElementById(PRINT_FORMS.DEFAULT);
  if (!formId) return;

  changeChutePrintsWidth();
  handleForm(formId, PRINT_FORMS.PRINTER, LISTENER_TYPES.CHANGE);
  handleForm(formId, PRINT_FORMS.PIER, LISTENER_TYPES.CHANGE);
  handleForm(formId, PRINT_FORMS.NUMBER_OF_COPIES, LISTENER_TYPES.INPUT);
  handleForm(formId, PRINT_FORMS.PTL_CHUTE, LISTENER_TYPES.CHANGE);
  handleForm(formId, PRINT_FORMS.PTL_AT_CHUTE, LISTENER_TYPES.CHANGE);
  handleForm(formId, PRINT_FORMS.PICKUP_POINT_ROUTE, LISTENER_TYPES.CHANGE);
});

const handleForm = (form, id, listenerType) => {
  const select = document.getElementById(id);
  if (!select) return;

  const setParams = () => {
    const formParams = new URLSearchParams(new FormData(form)).toString();
    const urlParams = new URLSearchParams(window.location.search);
    const pathName = window.location.pathname;
    const removeParamsFromUrl = () => {
      const urlParamsToArray = formParams.split("&");
      const formParamIndex = (url, include) => {
        const index = url.findIndex(urlPart => {
          if (urlPart.includes(`${include}`)) {
            return true;
          }
        });

        return index;
      };

      const ptlChute = document.getElementById(PRINT_FORMS.PTL_CHUTE);
      const ptlAtChute = document.getElementById(PRINT_FORMS.PTL_AT_CHUTE);
      const pickupPointRoute = document.getElementById(
        PRINT_FORMS.PICKUP_POINT_ROUTE
      );
      const resetSelects = (condition1, condition2, param1, param2) => {
        if (condition1) {
          resetAnotherSelect(`${PRINT_FORMS[param1]}`);
          urlParamsToArray.splice(
            formParamIndex(urlParamsToArray, `${PARAMS[param1]}`),
            1
          );
        }

        if (condition2) {
          resetAnotherSelect(`${PRINT_FORMS[param2]}`);
          urlParamsToArray.splice(
            formParamIndex(urlParamsToArray, `${PARAMS[param2]}`),
            1
          );
        }
      };

      if (id === PRINT_FORMS.PTL_CHUTE) {
        resetSelects(
          ptlAtChute,
          pickupPointRoute,
          "PTL_AT_CHUTE",
          "PICKUP_POINT_ROUTE"
        );
      } else if (id === PRINT_FORMS.PTL_AT_CHUTE) {
        resetSelects(
          ptlChute,
          pickupPointRoute,
          "PTL_CHUTE",
          "PICKUP_POINT_ROUTE"
        );
      } else if (id === PRINT_FORMS.PICKUP_POINT_ROUTE) {
        resetSelects(ptlChute, ptlAtChute, "PTL_CHUTE", "PTL_AT_CHUTE");
      }

      urlParamsToArray.splice(
        formParamIndex(urlParamsToArray, PARAMS.AUTH_TOKEN),
        1
      );

      let urlParamsToString = urlParamsToArray.join("&");

      for (const [key, value] of urlParams) {
        if (!key.includes(PRINT_FORMS.DEFAULT)) {
          urlParamsToString += `&${key}=${value}`;
        }
      }

      const urlWithKeys = urlParamsToString.split("&");
      const ptlChuteKeyIndex = formParamIndex(urlWithKeys, "ptl_chute=");
      const routeKeyIndex = formParamIndex(urlWithKeys, "pickup_point_route=");
      if (ptlChuteKeyIndex >= 0) {
        urlWithKeys.splice(ptlChuteKeyIndex, 1);
      }
      if (routeKeyIndex >= 0) {
        urlWithKeys.splice(routeKeyIndex, 1);
      }

      urlParamsToString = urlWithKeys.join("&");

      // TODO
      Turbo.visit(`${pathName}?${urlParamsToString}`);

      window.history.replaceState(
        {},
        document.title,
        `${pathName}?${urlParamsToString}`
      );
    };

    removeParamsFromUrl();
  };

  select.addEventListener(
    listenerType,
    listenerType === LISTENER_TYPES.INPUT ? debounce(setParams, 500) : setParams
  );
};

const resetAnotherSelect = id => {
  const select = document.getElementById(id);
  select.selectedIndex = 0;
};

const changeChutePrintsWidth = () => {
  const chutesPrints = document.querySelector(".chutes-prints");

  if (!chutesPrints) return;

  const aisleWrappers = chutesPrints.querySelectorAll(".aisle");

  aisleWrappers.forEach(wrapper => {
    const chutePrintBtns = wrapper.querySelectorAll(".btn-chute-print");
    const chutePrintBtnsArr = Array.from(chutePrintBtns);
    let btnAreShort = false;

    chutePrintBtnsArr.every(btn => {
      if (0 > btn.clientWidth - btn.scrollWidth) {
        btnAreShort = true;
        return false;
      } else return true;
    });

    if (btnAreShort) {
      chutePrintBtns.forEach(btn => {
        btn.classList.remove("col-1");
        btn.classList.add("col-15");
      });
    }
  });
};
